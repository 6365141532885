import React from "react";
import { Flex, Container, Heading, Box } from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import usePrismicNewsReports from "../../hooks/usePrismicNewsReports";
import EventsCard from "../../components/newsReports/EventsCard";
import { DEFAULT_IMAGE } from "../../static/constants";

const Events = () => {
  const { language } = useTranslation();
  const data = usePrismicNewsReports();
  
  return (
    <Main>
      <Flex direction="column" overflow="hidden">
        <Container px="5" maxW="7xl">
          <Seo
            title={data.calendarTitle}
            description={data.calendarTitle}
            link={`https://www.fsm.am/${language}/events`}
          />
          <SmallNav>{data.calendarTitle}</SmallNav>
          <Heading
            as="h1"
            fontSize={{ base: "2xl", sm: "3xl" }}
            fontWeight="900"
            mt={{ base: "3", sm: "5" }}
            mb={{ base: "1.875rem", sm: "10" }}
          >
            {data.calendarTitle}
          </Heading>
          <Flex
            flexDirection={{ base: "column", sm: "row" }}
            justifyContent="space-between"
            flexWrap="wrap"
            rowGap="25px"
            marginBottom="50px"
          >
            {data.events
              ?.sort((a, b) => new Date(b.date) - new Date(a.date))
              ?.map((slide, idx) => (
                <Box key={idx} width={{ base: "100%", sm: "31%" }}>
                  <EventsCard
                    title={slide.title2}
                    image={{
                      file: { url: slide.image ? slide.image : DEFAULT_IMAGE },
                    }}
                    description={slide.description}
                    cardDate={slide.date}
                    date
                    idNews={slide.id && slide.id}
                    mainButton
                  />
                </Box>
              ))}
          </Flex>
        </Container>
      </Flex>
    </Main>
  );
};

export default Events;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;



// import React from "react";
// import { Container, Heading, Box, Grid, GridItem } from "@chakra-ui/react";
// import { useTranslation } from "gatsby-plugin-react-i18next";
// import { graphql } from "gatsby";
// import Seo from "../../components/SEO";
// import Main from "../../components/layouts/Main";
// import SmallNav from "../../components/smallNav";
// import usePrismicNewsReports from "../../hooks/usePrismicNewsReports";
// import EventsCard from "../../components/newsReports/EventsCard";
// import { DEFAULT_IMAGE } from "../../static/constants";

// const Events = () => {
//   const { language } = useTranslation();
//   const data = usePrismicNewsReports();
//   return (
//     <Main>
//       <Box overflow="hidden">
//         <Container px="5" maxW="7xl">
//           <Seo
//             title={data.calendarTitle}
//             description={data.calendarTitle}
//             link={`https://www.fsm.am/${language}/events`}
//           />
//           <SmallNav>{data.calendarTitle}</SmallNav>
//           <Heading
//             as="h1"
//             fontSize={{ base: "2xl", sm: "3xl" }}
//             fontWeight="900"
//             mt={{ base: "3", sm: "5" }}
//             mb={{ base: "1.875rem", sm: "10" }}
//           >
//             {data.calendarTitle}
//           </Heading>
//           <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={10}>
//             {data.events
//               ?.sort((a, b) => new Date(b.date) - new Date(a.date))
//               ?.map((slide, idx) => (
//                 <GridItem  width="100%">
//                   <EventsCard
//                     title={slide.title2}
//                     image={{
//                       file: { url: slide.image ? slide.image : DEFAULT_IMAGE },
//                     }}
//                     description={slide.description}
//                     cardDate={slide.date}
//                     date
//                     idNews={slide.id && slide.id}
//                     mainButton
//                   />
//                 </GridItem>
//               ))}
//           </Grid>
//         </Container>
//       </Box>
//     </Main>
//   );
// };

// export default Events;

// export const query = graphql`
//   query ($language: String!) {
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
